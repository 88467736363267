<template>
    <div
        v-if="!activeChat"
        class="badge">
        <div
            v-if="currentProfile.companyLogoUrl"
            class="img">
            <img
                :src="currentProfile.companyLogoUrl"
                :alt="currentProfile.title">
        </div>
        <h2>
            {{ currentProfile.title }}
            <small v-if="currentProfile.subline">{{ currentProfile.subline }}</small>
        </h2>
    </div>
    <template v-if="activeChat">
        <Chat
            :company-id="currentProfile.uid"
            :company-title="currentProfile.title" />
    </template>
    <template v-else-if="canStartChat">
        <h2>Möchten Sie mit uns chatten?</h2>
        <p>Geben Sie Ihren Namen ein, um einen Chat mit uns zu starten. Mit dem Klick auf Chart starten, wird eine Chatanfrage gesendet, die gewöhnlich in wenigen Augenblicken beantwortet wird.</p>
        <div class="form-item">
            <input
                v-model="state.chatName"
                type="text"
                placeholder="Dein Name">
        </div>
        <button
            class="btn primary"
            @click="startChat">
            Chat starten
        </button>
    </template>
    <template v-else-if="isChatPending">
        <h2>Vielen Dank für Ihre Anfrage!</h2>
        <p>Wir werden diese in wenigen Augenblicken beantworten. Bitte haben Sie einen Moment Geduld.</p>
    </template>
    <template v-else-if="isChatPaused">
        <h2>Vielen Dank für Ihren Besuch an unserem Messestand! Aktuell sind alle unsere Mitarbeiter*innen in einem persönlichen Gespräch.</h2>
        <p>Wir freuen uns, wenn Sie zu einem späteren Zeitpunkt noch einmal bei uns vorbeischauen. Sollte dies nicht möglich sein, nutzen Sie gern die im Bereich „Über uns“ angegebenen Kontaktmöglichkeiten.</p>
    </template>
    <template v-else-if="isChatFull">
        <h2>Vielen Dank für Ihren Besuch an unserem Messestand! Aktuell sind alle unsere Mitarbeiter*innen in einem persönlichen Gespräch.</h2>
        <p>Wir freuen uns, wenn Sie zu einem späteren Zeitpunkt noch einmal bei uns vorbeischauen. Sollte dies nicht möglich sein, nutzen Sie gern die im Bereich „Über uns“ angegebenen Kontaktmöglichkeiten.</p>
    </template>
    <template v-else />
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeUnmount, reactive} from 'vue'
import {IActiveChat, ISocketCompanyState} from "@/types/socket";
import {requestChat, sendChatEnd, sendChatRead} from "@/api/socket/Client";
import {ComputedRef} from "@vue/reactivity";
import FairProfile from "@/models/FairProfile";
import FairComposition from "@/compositions/FairComposition";
import {useStore} from "vuex";
import Chat from "@/components/Chat.vue";
import {useRoute} from "vue-router";

export default defineComponent({
    name: "FairProfileChatTab",
    components: {Chat},
    setup() {
        const store = useStore();
        const state = reactive({
            chatName: store.getters['user/getUsername']
        })
        const userId = computed(() => {
            return store.getters['user/id'];
        })
        const {params: routeParams} = useRoute();
        const currentRouteProfile = routeParams.profile;
        const {currentProfile, trackFairProfileEvent} = FairComposition();

        const canStartChat = computed(() => {
            return !isChatPaused.value && !activeChat.value && !isChatPending.value && !isChatFull.value
        })

        const isChatPaused = computed(() => {
            if (!currentProfile.value) {
                return false;
            }
            console.log('TEST: currentProfile', currentProfile.value);
            const currentCompanyState: ISocketCompanyState = store.getters['socket/companyState'](currentProfile.value.uid);
            if (!currentCompanyState) {
                return false;
            }
            return currentCompanyState.pause
        })

        const isChatPending = computed(() => {
            if (!currentProfile.value) {
                return false;
            }
            return store.getters['socket/chatRequestByCompany'](currentProfile.value.uid);
        })

        const isChatFull = computed(() => {
            if (!currentProfile.value) {
                return false;
            }
            const currentCompanyState: ISocketCompanyState = store.getters['socket/companyState'](currentProfile.value.uid);
            if (!currentCompanyState) {
                return false;
            }
            return currentCompanyState.full
        })

        const activeChat = computed<IActiveChat | null>(() => {
            if (!currentProfile.value) {
                return false;
            }
            return store.getters['socket/activeChatByCompany'](currentProfile.value.uid);
        })

        const setRead = () => {
            if (activeChat.value && currentProfile.value && activeChat.value && activeChat.value.messages.length !== activeChat.value.readIndex) {
                sendChatRead(currentProfile.value.uid, activeChat.value.userId);
            }
        }

        const startChat = () => {
            if (currentProfile.value && state.chatName) {
                store.dispatch('user/setUsername', state.chatName)
                requestChat(currentProfile.value, state.chatName)
                trackFairProfileEvent('request-chat');
            }
        }

        onBeforeUnmount(() => {
            // prüfen ob chat beendet war, wenn ja, dann kann er jetzt endgültig weg (hier muss man sich die parameter leider nochmal extra holen, da über die computed die werte schon weg sind / die route wurde vorher geändert)
            const lastProfile = store.getters['fair-profiles/oneBySlug'](currentRouteProfile);
            if (!lastProfile) {
                return;
            }
            const lastActiveChat = store.getters['socket/activeChatByCompany'](lastProfile.uid);
            if (lastActiveChat && lastActiveChat.end) {
                sendChatEnd(lastProfile.uid, userId.value)
            }
        });

        return {
            state,
            currentProfile,
            startChat,
            isChatFull,
            isChatPaused,
            isChatPending,
            canStartChat,
            activeChat,
            setRead
        }
    }
});
</script>

<style scoped>

</style>
