
import SVideo from "@/components/base/SVideo.vue";
import SLink from "@/components/base/SLink.vue";
import FairComposition from '@/compositions/FairComposition';
import Fair from "@/models/Fair";
import FairProfile from "@/models/FairProfile";
import {ComputedRef, Ref} from "@vue/reactivity";
import {computed, defineComponent, onBeforeUnmount, onMounted, onUnmounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {joinCompany, leaveCompany, sendChatRead} from "@/api/socket/Client";
import {useStore} from "vuex";
import {IActiveChat} from "@/types/socket.ts";
import FairProfileChatTab from "@/components/base/FairProfileChatTab.vue";
import FairProfileVideoTab from "@/components/base/FairProfileVideoTab.vue";

export interface Tab {
    name: string;
    label: string;
    classes: string[];
    key: string;
}

export default defineComponent({
    name: "FairProfile",
    components: {
        FairProfileVideoTab,
        FairProfileChatTab,
        SVideo,
        SLink
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const tabs = computed<Tab[]>(() => {
            const t: Tab[] = [
                {name: 'ueber-uns', label: 'Über uns', classes: [], key: 'job-general'}
            ];

            if (currentProfile.value && currentFair.value) {
                if (currentFair.value.hasVideo && currentProfile.value?.hasVideo) {
                    t.push({name: 'video', label: 'Videoanruf', classes: ['icon-video', 'blue'], key: 'video'});
                }

                if (currentFair.value.hasChat && currentProfile.value?.hasChat) {
                    t.push({name: 'chat', label: 'Chat', classes: ['icon-chat', 'green'], key: 'chat'});
                }

                if (currentFair.value.linkToThaff && currentProfile.value?.linkToThaff && currentProfile.value?.jobs.length) {
                    t.push({name: 'stellen', label: 'Stellen', classes: ['icon-jobs', 'red'], key: 'jobs'});
                }
            }

            return t;
        })
        const {
            toggleProfile,
            currentProfile,
            currentFair,
            trackFairProfileEvent
        } = FairComposition();

        let t = route?.params?.tab ?? '';
        if (!t || typeof t !== 'string') {
            t = tabs.value[0].name;
        }
        if (tabs.value && t) {
            const index = tabs.value.find(el => el.name === t) || null;
            if (!index) {
                t = tabs.value[0].name;
            }
        }

        const profileSlug = route?.params.profile ?? null;
        if (!profileSlug) {
            toggleProfile();
        }

        const activeTab: Ref<string> = ref(t);
        watch(() => route.params, (params) => {
            const tab = params?.tab ?? null;

            if (typeof tab === 'string' && tabs.value.find(el => el.name === tab)) {
                activeTab.value = tab;
                return;
            }

            activeTab.value = tabs.value[0].name;
            return;
        })

        const profile: ComputedRef<FairProfile | null> = computed(() => {
            return currentProfile.value as FairProfile;
        })


        // socket stuff für company rooms
        let cacheInstance = currentProfile.value;
        onMounted(() => {
            if (currentProfile.value) {
                joinCompany(currentProfile.value);
                trackFairProfileEvent('tab');
                if(activeTab.value){
                    const tabObject = tabs.value.find(el => el.name === activeTab.value);
                    if(tabObject){
                        trackFairProfileEvent('tab', tabObject.key);
                    }
                }
            }
        })

        watch(currentProfile, (newProfile, oldProfile) => {
            // wenn direkt zwischen den profiles umher gewechselt wird
            if (newProfile !== oldProfile && newProfile) {
                if (cacheInstance) {
                    leaveCompany(cacheInstance);
                }
                cacheInstance = newProfile;
                joinCompany(cacheInstance);
            }
        })

        onBeforeUnmount(() => {
            if (cacheInstance) {
                leaveCompany(cacheInstance);
            }
        })

        const activeChat = computed<IActiveChat | null>(() => {
            if (!currentProfile.value) {
                return false;
            }
            return store.getters['socket/activeChatByCompany'](currentProfile.value.uid);
        })

        const setRead = () => {
            if (activeChat.value && currentProfile.value && activeChat.value && activeChat.value.messages.length !== activeChat.value.readIndex) {
                sendChatRead(currentProfile.value.uid, activeChat.value.userId);
            }
        }

        const trackIconClick = (icon: string) => {
            trackFairProfileEvent('social', icon);
        }
        const trackLink = (link: string) => {
            trackFairProfileEvent('link', link);
        }

        return {
            tabs,
            activeTab,
            currentProfile,
            currentFair,
            profile,
            activeChat,
            setRead,
            trackFairProfileEvent,
            trackIconClick,
            trackLink
        }
    },
    data: () => ({
        ready: false
    }),
    computed: {
        downloads(): { id: number, url: string, title: string }[]{
            if(!this.currentProfile || !this.currentProfile.downloads){
                return [];
            }
            return this.currentProfile.downloads.filter(el => el.url);
        }
    },
    beforeUnmount() {
        this.ready = false;
    },
    mounted() {
        window.setTimeout(() => {
            this.ready = true;
        }, 10);
    },
    methods: {
        close() {
            const slug = this.$route?.params?.slug ?? null;
            if (slug && typeof slug === 'string') {
                this.ready = false;
                window.setTimeout(() => {
                    this.$router.push({name: 'fair', params: {slug: slug}});
                }, 100);
            }
        },
        openTab(tab: string) {
            if (this.$route.name) {

                const tabObject = this.tabs.find(el => el.name === tab) || null;
                if(tabObject){
                    this.trackFairProfileEvent('tab', tabObject.key);
                }
                this.$router.push({name: this.$route.name, params: {tab: tab}});
            }
        }
    }
})
