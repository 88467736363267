
import {computed, defineComponent, onBeforeUnmount, reactive} from 'vue'
import {IActiveChat, IActiveVideo, ISocketCompanyState} from "@/types/socket";
import {requestVideo, sendChatEnd} from "@/api/socket/Client";
import {ComputedRef} from "@vue/reactivity";
import FairProfile from "@/models/FairProfile";
import FairComposition from "@/compositions/FairComposition";
import {useStore} from "vuex";
import Chat from "@/components/Chat.vue";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
    name: "FairProfileVideoTab",
    components: {Chat},
    setup() {
        const store = useStore();
        const router = useRouter();
        const state = reactive({
            chatName: store.getters['user/getUsername']
        })

        const userId = computed(() => {
            return store.getters['user/id'];
        })
        const {params: routeParams} = useRoute();
        const currentRouteProfile = routeParams.profile;
        const currentRouteFair = routeParams.slug;
        const {currentProfile, trackFairProfileEvent} = FairComposition();

        const canStartChat = computed(() => {
            return !isChatPaused.value && !activeChat.value && !isChatPending.value && !isChatFull.value
        })

        const isChatPaused = computed(() => {
            if (!currentProfile.value) {
                return false;
            }
            const currentCompanyState: ISocketCompanyState = store.getters['socket/companyState'](currentProfile.value.uid);
            if (!currentCompanyState) {
                return false;
            }
            return currentCompanyState.videoPause
        })

        const isChatPending = computed(() => {
            if (!currentProfile.value) {
                return false;
            }
            return store.getters['socket/videoRequestByCompany'](currentProfile.value.uid);
        })

        const isChatFull = computed(() => {
            if (!currentProfile.value) {
                return false;
            }
            const currentCompanyState: ISocketCompanyState = store.getters['socket/companyState'](currentProfile.value.uid);
            if (!currentCompanyState) {
                return false;
            }
            return currentCompanyState.videoFull
        })

        const activeChat = computed<IActiveVideo | null>(() => {
            if (!currentProfile.value) {
                return false;
            }
            return store.getters['socket/activeVideoByCompany'](currentProfile.value.uid);
        })

        const startChat = () => {
            if (currentProfile.value && state.chatName) {
                store.dispatch('user/setUsername', state.chatName)
                requestVideo(currentProfile.value, state.chatName)
                trackFairProfileEvent('request-video');
            }
        }

        const openVideoWindow = () => {
            if (activeChat.value) {
                const route = router.resolve({name: 'fairVideoChat', params: {slug: currentRouteFair, roomUrl: activeChat.value.roomUrl}})
                window.open(route.href);
            }
        }

        onBeforeUnmount(() => {
            // prüfen ob chat beendet war, wenn ja, dann kann er jetzt endgültig weg (hier muss man sich die parameter leider nochmal extra holen, da über die computed die werte schon weg sind / die route wurde vorher geändert)
            const lastProfile = store.getters['fair-profiles/oneBySlug'](currentRouteProfile);
            if (!lastProfile) {
                return;
            }
            const lastActiveChat = store.getters['socket/activeVideoByCompany'](lastProfile.uid);
            if (lastActiveChat && lastActiveChat.end) {
                sendChatEnd(lastProfile.uid, userId.value)
            }
        });

        return {
            state,
            currentProfile,
            startChat,
            isChatFull,
            isChatPaused,
            isChatPending,
            canStartChat,
            activeChat,
            openVideoWindow
        }
    }
});
