<template>
    <div
        v-if="!activeChat"
        class="badge">
        <div
            v-if="currentProfile.companyLogoUrl"
            class="img">
            <img
                :src="currentProfile.companyLogoUrl"
                :alt="currentProfile.title">
        </div>
        <h2>
            {{ currentProfile.title }}
            <small v-if="currentProfile.subline">{{ currentProfile.subline }}</small>
        </h2>
    </div>
    <template v-if="activeChat && !activeChat.clientConnected">
        <h2>Videoanruf wurde gestartet</h2>
        <button
            class="btn primary"
            @click="openVideoWindow">
            Videoanruf teilnehmen
        </button>
    </template>
    <template v-else-if="activeChat">
        <h2>Videoanruf wurde gestartet</h2>
        <p>Sie sind bereits in dem Videoanruf aktiv</p>
    </template>
    <template v-else-if="canStartChat">
        <h2>Möchten Sie einen Videoanruf starten?</h2>
        <p>Geben Sie Ihren Namen ein, um einen Videoanruf mit uns zu starten. Mit dem Klick auf Videoanruf starten, wird eine Anfrage gesendet, die gewöhnlich in wenigen Augenblicken beantwortet wird.</p>
        <div class="form-item">
            <input
                v-model="state.chatName"
                type="text"
                placeholder="Dein Name">
        </div>
        <button
            class="btn primary"
            @click="startChat">
            Videoanruf starten
        </button>
    </template>
    <template v-else-if="isChatPending">
        <h2>Vielen Dank für Ihre Anfrage!</h2>
        <p>Wir werden diese in wenigen Augenblicken beantworten. Bitte haben Sie einen Moment Geduld.</p>
    </template>
    <template v-else-if="isChatPaused">
        <h2>Vielen Dank für Ihren Besuch an unserem Messestand! Aktuell sind alle unsere Mitarbeiter*innen in einem persönlichen Gespräch.</h2>
        <p>Wir freuen uns, wenn Sie zu einem späteren Zeitpunkt noch einmal bei uns vorbeischauen. Sollte dies nicht möglich sein, nutzen Sie gern die im Bereich „Über uns“ angegebenen Kontaktmöglichkeiten.</p>
    </template>
    <template v-else-if="isChatFull">
        <h2>Vielen Dank für Ihren Besuch an unserem Messestand! Aktuell sind alle unsere Mitarbeiter*innen in einem persönlichen Gespräch.</h2>
        <p>Wir freuen uns, wenn Sie zu einem späteren Zeitpunkt noch einmal bei uns vorbeischauen. Sollte dies nicht möglich sein, nutzen Sie gern die im Bereich „Über uns“ angegebenen Kontaktmöglichkeiten.</p>
    </template>
    <template v-else />
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeUnmount, reactive} from 'vue'
import {IActiveChat, IActiveVideo, ISocketCompanyState} from "@/types/socket";
import {requestVideo, sendChatEnd} from "@/api/socket/Client";
import {ComputedRef} from "@vue/reactivity";
import FairProfile from "@/models/FairProfile";
import FairComposition from "@/compositions/FairComposition";
import {useStore} from "vuex";
import Chat from "@/components/Chat.vue";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
    name: "FairProfileVideoTab",
    components: {Chat},
    setup() {
        const store = useStore();
        const router = useRouter();
        const state = reactive({
            chatName: store.getters['user/getUsername']
        })

        const userId = computed(() => {
            return store.getters['user/id'];
        })
        const {params: routeParams} = useRoute();
        const currentRouteProfile = routeParams.profile;
        const currentRouteFair = routeParams.slug;
        const {currentProfile, trackFairProfileEvent} = FairComposition();

        const canStartChat = computed(() => {
            return !isChatPaused.value && !activeChat.value && !isChatPending.value && !isChatFull.value
        })

        const isChatPaused = computed(() => {
            if (!currentProfile.value) {
                return false;
            }
            const currentCompanyState: ISocketCompanyState = store.getters['socket/companyState'](currentProfile.value.uid);
            if (!currentCompanyState) {
                return false;
            }
            return currentCompanyState.videoPause
        })

        const isChatPending = computed(() => {
            if (!currentProfile.value) {
                return false;
            }
            return store.getters['socket/videoRequestByCompany'](currentProfile.value.uid);
        })

        const isChatFull = computed(() => {
            if (!currentProfile.value) {
                return false;
            }
            const currentCompanyState: ISocketCompanyState = store.getters['socket/companyState'](currentProfile.value.uid);
            if (!currentCompanyState) {
                return false;
            }
            return currentCompanyState.videoFull
        })

        const activeChat = computed<IActiveVideo | null>(() => {
            if (!currentProfile.value) {
                return false;
            }
            return store.getters['socket/activeVideoByCompany'](currentProfile.value.uid);
        })

        const startChat = () => {
            if (currentProfile.value && state.chatName) {
                store.dispatch('user/setUsername', state.chatName)
                requestVideo(currentProfile.value, state.chatName)
                trackFairProfileEvent('request-video');
            }
        }

        const openVideoWindow = () => {
            if (activeChat.value) {
                const route = router.resolve({name: 'fairVideoChat', params: {slug: currentRouteFair, roomUrl: activeChat.value.roomUrl}})
                window.open(route.href);
            }
        }

        onBeforeUnmount(() => {
            // prüfen ob chat beendet war, wenn ja, dann kann er jetzt endgültig weg (hier muss man sich die parameter leider nochmal extra holen, da über die computed die werte schon weg sind / die route wurde vorher geändert)
            const lastProfile = store.getters['fair-profiles/oneBySlug'](currentRouteProfile);
            if (!lastProfile) {
                return;
            }
            const lastActiveChat = store.getters['socket/activeVideoByCompany'](lastProfile.uid);
            if (lastActiveChat && lastActiveChat.end) {
                sendChatEnd(lastProfile.uid, userId.value)
            }
        });

        return {
            state,
            currentProfile,
            startChat,
            isChatFull,
            isChatPaused,
            isChatPending,
            canStartChat,
            activeChat,
            openVideoWindow
        }
    }
});
</script>

<style scoped>

</style>
