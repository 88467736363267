<template>
    <div
        v-if="currentFair !== null && currentProfile"
        id="messeToolbox"
        :class="{open: ready === true}"
        class="toolbox right">
        <div class="inner">
            <button
                class="btn btn-icon primary btn-close icon-close"
                @click="close" />
            <ul class="tabs">
                <li
                    v-for="tab in tabs"
                    :key="tab.name"
                    :class="{active: tab.name === activeTab}">
                    <button
                        :class="tab.classes"
                        @click="openTab(tab.name)">
                        {{ tab.label }}
                    </button>
                </li>
            </ul>

            <div
                v-if="activeTab === 'ueber-uns'"
                class="toolbox-content">
                <div class="badge">
                    <div
                        v-if="currentProfile.companyLogoUrl"
                        class="img">
                        <img
                            :src="currentProfile.companyLogoUrl"
                            alt="">
                    </div>
                    <h2>
                        {{ currentProfile.title }}
                        <small v-if="currentProfile.subline">{{ currentProfile.subline }}</small>
                    </h2>
                </div>

                <div
                    v-if="currentProfile.imageUrl"
                    class="img">
                    <img
                        :src="currentProfile.imageUrl"
                        alt="Messe Bild">
                </div>

                <SVideo
                    v-if="currentProfile.video"
                    :video-data="currentProfile.video" />

                <div
                    class="fair-content"
                    v-html="currentProfile.text" />

                <div class="fair-downloads">
                    <a
                        v-for="download in downloads"
                        :key="download.id"
                        :title="download.title"
                        :href="download.url"
                        download
                        target="_blank"
                        class="btn primary"
                        @click="trackLink('download')">{{ download.title }}
                    </a>
                </div>


                <div class="company-footer grid no-gap">
                    <div class="col-1-2">
                        <div
                            v-if="currentProfile.contactPartners"
                            class="inner grey">
                            <h3>Ihre Ansprechpartner</h3>
                            <ul
                                v-for="contact in currentProfile.contactPartners"
                                :key="contact.id"
                                class="icon-text-list">
                                <li
                                    v-if="contact.firstName || contact.lastName"
                                    class="icon-user">
                                    {{ contact.firstName }} {{ contact.lastName }}
                                </li>
                                <li
                                    v-if="contact.email"
                                    class="icon-mail">
                                    <a
                                        :href="'mailto:' + contact.email"
                                        target="_blank"
                                        title=""
                                        @click="trackLink('mail')">{{ contact.email }}
                                    </a>
                                </li>
                                <li
                                    v-if="contact.phone"
                                    class="icon-phone">
                                    <a
                                        :href="'tel:' + contact.phone"
                                        title="Anruf starten"
                                        target="_blank"
                                        @click="trackLink('phone')">
                                        {{ contact.phone }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-1-2">
                        <div class="inner">
                            <h3 v-if="currentProfile.companyZip || currentProfile.companyHouseNumber || currentProfile.companyLocation || currentProfile.companyStreet || currentProfile.companyLink">
                                Kontakt
                            </h3>
                            <ul class="icon-text-list">
                                <li
                                    v-if="currentProfile.companyZip || currentProfile.companyHouseNumber || currentProfile.companyLocation || currentProfile.companyStreet"
                                    class="icon-address">
                                    <template v-if="currentProfile.companyStreet || currentProfile.companyHouseNumber">
                                        {{ currentProfile.companyStreet }}
                                        {{ currentProfile.companyHouseNumber }}
                                        <br>
                                    </template>
                                    <template v-if=" currentProfile.companyZip || currentProfile.companyLocation">
                                        {{ currentProfile.companyZip }} {{ currentProfile.companyLocation }}<br>
                                    </template>
                                    Deutschland
                                </li>
                                <li
                                    v-if="currentProfile.companyLink"
                                    class="icon-home">
                                    <a
                                        :href="currentProfile.companyLink"
                                        target="_blank"
                                        title="Zur Unternehmensseite"
                                        @click="trackLink('company')">{{ currentProfile.companyLink }}
                                    </a>
                                </li>
                            </ul>
                            <ul
                                v-if="currentProfile.socialUrls"
                                class="list-social">
                                <li v-if="currentProfile.socialUrls.facebookUrl">
                                    <a
                                        :href="currentProfile.socialUrls.facebookUrl"
                                        title="Zur Facebookseite gehen"
                                        target="_blank"
                                        class="icon-facebook"
                                        @click="trackIconClick('facebook')" />
                                </li>
                                <li v-if="currentProfile.socialUrls.youtubeUrl">
                                    <a
                                        :href="currentProfile.socialUrls.youtubeUrl"
                                        title="Zu Youtube gehen"
                                        target="_blank"
                                        class="icon-youtube"
                                        @click="trackIconClick('youtube')" />
                                </li>
                                <li v-if="currentProfile.socialUrls.twitterUrl">
                                    <a
                                        :href="currentProfile.socialUrls.twitterUrl"
                                        title="Zu Twitter gehen gehen"
                                        target="_blank"
                                        class="icon-twitter"
                                        @click="trackIconClick('twitter')" />
                                </li>
                                <li v-if="currentProfile.socialUrls.xingUrl">
                                    <a
                                        :href="currentProfile.socialUrls.xingUrl"
                                        title="Zur Xing Seite gehen"
                                        target="_blank"
                                        class="icon-xing"
                                        @click="trackIconClick('xing')" />
                                </li>
                                <li v-if="currentProfile.socialUrls.instagramUrl">
                                    <a
                                        :href="currentProfile.socialUrls.instagramUrl"
                                        title="Zur Instagram Seite gehen"
                                        target="_blank"
                                        class="icon-instagram"
                                        @click="trackIconClick('instagram')" />
                                </li>
                                <li v-if="currentProfile.socialUrls.linkedInUrl">
                                    <a
                                        :href="currentProfile.socialUrls.linkedInUrl"
                                        title="Zur LinkedIn Seite gehen"
                                        target="_blank"
                                        class="icon-linkedin"
                                        @click="trackIconClick('linkedIn')" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="activeTab === 'chat'"
                class="toolbox-content"
                @scroll="setRead">
                <FairProfileChatTab />
            </div>

            <div
                v-if="activeTab === 'video'"
                class="toolbox-content"
                @scroll="setRead">
                <FairProfileVideoTab />
            </div>

            <div
                v-if="activeTab === 'stellen'"
                class="toolbox-content">
                <h2>Ausgeschriebene Stellen</h2>
                <ul class="list-jobs">
                    <li
                        v-for="job in currentProfile.jobs"
                        :key="job.id">
                        <h2>{{ job.title }}</h2>
                        <div class="info">
                            <span
                                v-if="job.category"
                                class="label icon-tag">
                                {{ job.category }}
                            </span>
                            <span
                                v-if="job.location"
                                class="label icon-address">
                                {{ job.location }}
                            </span>
                            <div
                                v-if="job.url"
                                class="buttons">
                                <a
                                    :href="job.url"
                                    target="_blank"
                                    title="Zur Stellenangebotdetailseite auf der Thaff.de"
                                    class="btn primary"
                                    @click="trackLink('job')">Details
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import SVideo from "@/components/base/SVideo.vue";
import SLink from "@/components/base/SLink.vue";
import FairComposition from '@/compositions/FairComposition';
import Fair from "@/models/Fair";
import FairProfile from "@/models/FairProfile";
import {ComputedRef, Ref} from "@vue/reactivity";
import {computed, defineComponent, onBeforeUnmount, onMounted, onUnmounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {joinCompany, leaveCompany, sendChatRead} from "@/api/socket/Client";
import {useStore} from "vuex";
import {IActiveChat} from "@/types/socket.ts";
import FairProfileChatTab from "@/components/base/FairProfileChatTab.vue";
import FairProfileVideoTab from "@/components/base/FairProfileVideoTab.vue";

export interface Tab {
    name: string;
    label: string;
    classes: string[];
    key: string;
}

export default defineComponent({
    name: "FairProfile",
    components: {
        FairProfileVideoTab,
        FairProfileChatTab,
        SVideo,
        SLink
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const tabs = computed<Tab[]>(() => {
            const t: Tab[] = [
                {name: 'ueber-uns', label: 'Über uns', classes: [], key: 'job-general'}
            ];

            if (currentProfile.value && currentFair.value) {
                if (currentFair.value.hasVideo && currentProfile.value?.hasVideo) {
                    t.push({name: 'video', label: 'Videoanruf', classes: ['icon-video', 'blue'], key: 'video'});
                }

                if (currentFair.value.hasChat && currentProfile.value?.hasChat) {
                    t.push({name: 'chat', label: 'Chat', classes: ['icon-chat', 'green'], key: 'chat'});
                }

                if (currentFair.value.linkToThaff && currentProfile.value?.linkToThaff && currentProfile.value?.jobs.length) {
                    t.push({name: 'stellen', label: 'Stellen', classes: ['icon-jobs', 'red'], key: 'jobs'});
                }
            }

            return t;
        })
        const {
            toggleProfile,
            currentProfile,
            currentFair,
            trackFairProfileEvent
        } = FairComposition();

        let t = route?.params?.tab ?? '';
        if (!t || typeof t !== 'string') {
            t = tabs.value[0].name;
        }
        if (tabs.value && t) {
            const index = tabs.value.find(el => el.name === t) || null;
            if (!index) {
                t = tabs.value[0].name;
            }
        }

        const profileSlug = route?.params.profile ?? null;
        if (!profileSlug) {
            toggleProfile();
        }

        const activeTab: Ref<string> = ref(t);
        watch(() => route.params, (params) => {
            const tab = params?.tab ?? null;

            if (typeof tab === 'string' && tabs.value.find(el => el.name === tab)) {
                activeTab.value = tab;
                return;
            }

            activeTab.value = tabs.value[0].name;
            return;
        })

        const profile: ComputedRef<FairProfile | null> = computed(() => {
            return currentProfile.value as FairProfile;
        })


        // socket stuff für company rooms
        let cacheInstance = currentProfile.value;
        onMounted(() => {
            if (currentProfile.value) {
                joinCompany(currentProfile.value);
                trackFairProfileEvent('tab');
                if(activeTab.value){
                    const tabObject = tabs.value.find(el => el.name === activeTab.value);
                    if(tabObject){
                        trackFairProfileEvent('tab', tabObject.key);
                    }
                }
            }
        })

        watch(currentProfile, (newProfile, oldProfile) => {
            // wenn direkt zwischen den profiles umher gewechselt wird
            if (newProfile !== oldProfile && newProfile) {
                if (cacheInstance) {
                    leaveCompany(cacheInstance);
                }
                cacheInstance = newProfile;
                joinCompany(cacheInstance);
            }
        })

        onBeforeUnmount(() => {
            if (cacheInstance) {
                leaveCompany(cacheInstance);
            }
        })

        const activeChat = computed<IActiveChat | null>(() => {
            if (!currentProfile.value) {
                return false;
            }
            return store.getters['socket/activeChatByCompany'](currentProfile.value.uid);
        })

        const setRead = () => {
            if (activeChat.value && currentProfile.value && activeChat.value && activeChat.value.messages.length !== activeChat.value.readIndex) {
                sendChatRead(currentProfile.value.uid, activeChat.value.userId);
            }
        }

        const trackIconClick = (icon: string) => {
            trackFairProfileEvent('social', icon);
        }
        const trackLink = (link: string) => {
            trackFairProfileEvent('link', link);
        }

        return {
            tabs,
            activeTab,
            currentProfile,
            currentFair,
            profile,
            activeChat,
            setRead,
            trackFairProfileEvent,
            trackIconClick,
            trackLink
        }
    },
    data: () => ({
        ready: false
    }),
    computed: {
        downloads(): { id: number, url: string, title: string }[]{
            if(!this.currentProfile || !this.currentProfile.downloads){
                return [];
            }
            return this.currentProfile.downloads.filter(el => el.url);
        }
    },
    beforeUnmount() {
        this.ready = false;
    },
    mounted() {
        window.setTimeout(() => {
            this.ready = true;
        }, 10);
    },
    methods: {
        close() {
            const slug = this.$route?.params?.slug ?? null;
            if (slug && typeof slug === 'string') {
                this.ready = false;
                window.setTimeout(() => {
                    this.$router.push({name: 'fair', params: {slug: slug}});
                }, 100);
            }
        },
        openTab(tab: string) {
            if (this.$route.name) {

                const tabObject = this.tabs.find(el => el.name === tab) || null;
                if(tabObject){
                    this.trackFairProfileEvent('tab', tabObject.key);
                }
                this.$router.push({name: this.$route.name, params: {tab: tab}});
            }
        }
    }
})
</script>

<style scoped>

</style>
