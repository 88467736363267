
import {
    computed,
    defineComponent,
    getCurrentInstance,
    nextTick,
    onUnmounted, onUpdated,
    reactive,
    ref,
    watch,
} from 'vue'
import {useStore} from "vuex";
import {sendChatEnd, sendChatMessage, sendChatRead} from "@/api/socket/Client";
import moment from "moment";
import {IActiveChat, IChatMessage} from "@/types/socket";

export default defineComponent({
    name: "Chat",
    props: {
        companyId: {
            type: String,
            required: true
        },
        companyTitle: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const store = useStore();
        const scrollContainer = ref<HTMLElement | null>(null)
        let disableNextScroll = false;
        const userId = computed(() => {
            return store.getters['user/id'];
        })

        const state = reactive({
            message: ''
        })

        const sanitizeText = (newValue: string) => {
            const regex = /[<>&%$§'"]/gi;
            state.message = newValue.replace(regex, "");
        }

        const sendMessage = () => {
            if (!state.message.trim()) {
                return;
            }

            sendChatMessage(props.companyId, userId.value, state.message)
            state.message = '';
        }

        const endChat = () => {
            sendChatEnd(props.companyId, userId.value);
        }

        const setRead = () => {
            if (!disableNextScroll && activeChat.value && activeChat.value.messages.length !== activeChat.value.readIndex) {
                sendChatRead(props.companyId, userId.value);
            }
            disableNextScroll = false;
        }

        const activeChat = computed<IActiveChat | null>(() => {
            return store.getters['socket/activeChatByCompany'](props.companyId);
        })

        const getChatContact = computed(() => {
            if (!activeChat.value?.chatContactId) {
                return null;
            }

            return store.getters['chat-contact/get'](activeChat.value?.chatContactId)
        })

        const scrollToEnd = () => {
            if (!scrollContainer.value) {
                return;
            }
            scrollContainer.value.scrollTop = scrollContainer.value.scrollHeight;
            disableNextScroll = true;
        }

        const getChatMessages = computed<IChatMessage[]>(() => {
            if (activeChat.value) {
                nextTick(() => scrollToEnd());
                return activeChat.value.messages;
            }
            return [];
        })

        // übel dumm...keine ahnung warum hier nicht wie im backend direkt das computed neu triggert sobald sich etwas an den getChatMessages ändert
        let oldLength = getChatMessages.value.length;
        onUpdated(() => {
            if (oldLength < getChatMessages.value.length) {
                nextTick(() => scrollToEnd());
                oldLength = getChatMessages.value.length;
            }
        })

        const getSenderName = (senderId: string) => {
            if (senderId === userId.value) {
                return 'Du';
            }

            if (!getChatContact.value) {
                // warum auch immer?
                return '';
            }

            return getChatContact.value.name;
        }

        const getFormattedTime = computed(() => {
            return (timestamp: number) => {
                return moment(timestamp).locale('de').fromNow()
            }
        });

        // update component für refresh der uhrzeit
        const instance = getCurrentInstance();
        let interval = setInterval(() => {
            instance?.proxy?.$forceUpdate();
        }, 20000)

        onUnmounted(() => {
            clearInterval(interval);
        })

        return {
            state,
            userId,
            activeChat,
            getChatContact,
            scrollContainer,
            getChatMessages,
            sendMessage,
            sanitizeText,
            getSenderName,
            getFormattedTime,
            endChat,
            setRead
        }
    }
});
